import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "text-left ml-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Languages = _resolveComponent("Languages")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isValidChain)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t("invalidNetwork")), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("youNeetNet", { networdName: _ctx.networdName })) + " ", 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Languages)
          ])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.contract && _ctx.provider)
            ? (_openBlock(), _createBlock(_component_router_view, {
                key: 0,
                contract: _ctx.contract,
                provider: _ctx.provider,
                accounts: _ctx.accounts,
                hasMetaMask: _ctx.hasMetaMask
              }, null, 8, ["contract", "provider", "accounts", "hasMetaMask"]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}