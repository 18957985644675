
import { defineComponent, computed } from "vue";
import { useUser, useIsSignedIn } from "@/utils/utils";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();

    const user = useUser();
    const isSignedIn = useIsSignedIn();

    const bgColor = computed(() => store.state.bgColor);

    return {
      user,
      isSignedIn,

      bgColor,
    };
  },
});
