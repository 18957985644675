import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Particles = _resolveComponent("Particles")!

  return (_openBlock(), _createBlock(_component_Particles, {
    id: "tsparticles",
    particlesInit: _ctx.particlesInit,
    particlesLoaded: _ctx.particlesLoaded,
    options: _ctx.options
  }, null, 8, ["particlesInit", "particlesLoaded", "options"]))
}