import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option,
          key: index,
          selected: option == _ctx.selectedValue ? true : false
        }, _toDisplayString(_ctx.$t("languages." + option)), 9, _hoisted_1))
      }), 128))
    ], 32)
  ]))
}