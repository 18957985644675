
import { defineComponent, ref, reactive, watch, computed, PropType } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import { ethers } from "ethers";

import nounsTokenJson from "@/abi/NounsToken";

import { ethereumConfig } from "@/config/project";
import { sleep } from "@/utils/utils";
import {
  usePrice,
  useWatchTransaction,
  useFire,
  useCurrentAndNextToken,
  useNFTs,
} from "./HomeUtils";

import { NFT, NFTData } from "./types";

import Animation from "./Animation.vue";
import Languages from "@/components/Languages.vue";
import Message from "@/components/Message.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    Animation,
    Message,
    Languages,
  },
  props: {
    contract: {
      type: ethers.Contract,
      required: true,
    },
    provider: {
      type: [ethers.providers.Web3Provider, ethers.providers.AlchemyProvider],
      required: true,
    },
    accounts: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    hasMetaMask: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18n();
    const loading = ref(false);

    const { nfts, updateNFT, updateNftData, updateOwnerData } = useNFTs(
      props.contract
    );
    const buying = reactive<{ [key: string]: boolean }>({});

    const { contractAddress, openseaUrl } = ethereumConfig;

    const { currentPrice, mintTime } = usePrice(props.contract);
    const { currentToken, nextToken } = useCurrentAndNextToken();
    const txWatchCallback = (status: number) => {
      if (status == 0) {
        buying[currentToken.value] = false;
        alert(i18n.t("sorryLowGasPrice"));
      }
    };

    const { transactionHash } = useWatchTransaction(
      props.provider,
      txWatchCallback
    );
    const { fire, fireOn } = useFire();

    const initEvent = () => {
      props.contract.removeAllListeners();
      props.contract.on("NounCreated", (event) => {
        console.log("NounCreated");
        updateNextToken();
      });
      props.contract.on("MintTimeUpdated", (event) => {
        updateMintTime(event.toNumber());
      });
      props.contract.on("NounBought", (tokenId, owner) => {
        updateNFT(tokenId.toString(), "owner", owner);
        if (buying[tokenId.toString()]) {
          if (owner == props.accounts[0]) {
            fire();
          }
        }

        buying[tokenId.toString()] = false;
      });
    };
    initEvent();

    const updateMintTime = (newMintTime: number) => {
      if (newMintTime > mintTime.value) {
        mintTime.value = newMintTime;
      }
    };

    const updateNextToken = async () => {
      props.contract.functions.getMintTime().then((_minttime) => {
        updateMintTime(_minttime[0].toNumber());
      });
      props.contract.functions.getCurrentToken().then((res) => {
        nextToken.value = res[0].toString();
      });
    };

    watch(currentToken, async () => {
      Promise.all(
        Array.from(Array.from(new Array(10)).keys()).map(async (i: number) => {
          const index = currentToken.value - i;
          if (index >= 0 && !nfts.value[String(index)]) {
            updateNftData(String(index));
            updateOwnerData(String(index));
          }
        })
      );
    });

    const bgColor = computed(() => {
      const nft = nfts.value[currentToken.value];
      if (nft) {
        return nft.bgColor;
      }
      return "bg-nouns-gray";
    });
    watch(bgColor, () => {
      store.commit("setBgColor", bgColor.value);
    });

    updateNextToken();

    const mintNouns = async () => {
      await props.provider.send("eth_requestAccounts", []);

      const signer = props.provider.getSigner();
      const contractWithSigner = new ethers.Contract(
        contractAddress,
        nounsTokenJson.abi,
        signer
      );

      loading.value = true;
      try {
        buying[currentToken.value] = true;
        const res = await contractWithSigner.functions.buy(currentToken.value, {
          value: ethers.utils.parseEther(String(currentPrice.value)),
        });
        transactionHash.value = res.hash;
        updateNextToken();
      } catch (e) {
        console.log(e);
        buying[currentToken.value] = false;
        alert(i18n.t("sorryLowPrice"));
      }
      loading.value = false;
    };

    const nftKeys = computed(() => {
      return Object.keys(nfts.value).sort((a, b) => {
        return Number(a) > Number(b) ? -1 : 1;
      });
    });

    const owners = computed(() => {
      return nftKeys.value.reduce(
        (ret: { [key: string]: boolean }, key: string) => {
          ret[key] = props.accounts.includes(nfts.value[key]?.owner);
          return ret;
        },
        {}
      );
    });

    return {
      loading,
      mintNouns,
      contractAddress,
      openseaUrl,

      nfts,
      nftKeys,
      owners,

      currentPrice,
      currentToken,

      buying,

      fireOn,
      fire,
      bgColor,
    };
  },
});
